<template>
  <div>
    <v-card v-if="items.length > 0">
      <v-card-title>
        <span class="text-h6">
          Bài viết: {{title}}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-list three-line v-if="items.length > 0">
            <template v-for="(item) in items">
              <div :key="item._id">
                <v-list-item
                >
                  <v-list-item-content>
                      <v-list-item-subtitle>
                        <span class="text-body blue--text">{{item.Owner}}</span>
                        <template v-if="item.Owner === getUser.email.split('@')[0] || getUser.Access === 'admin'">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="blue"
                            @click="doEditReply(item)"
                          >
                            <v-icon dark>
                              mdi-comment-edit-outline
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-list-item-subtitle>
                      <v-list-item-title>
                          <div v-html="showContent(item.Content)"></div>
                      </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </div>
            </template>
            <v-list-item
            >
              <v-list-item-content>
                  <v-list-item-title>
                    <quill-editor v-model="Content" :options="{theme: 'snow'}" />
                    <br />
                    <v-btn
                      v-if="editReply === undefined"
                      color="green"
                      :disabled="!Content"
                      @click="doReply"
                    >
                      Đăng
                    </v-btn>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="blue"
                        :disabled="!Content"
                        @click="doUpdateReply"
                      >
                        Cập nhật
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        color="orange"
                        @click="doCancelUpdate"
                      >
                        Hủy
                      </v-btn>
                    </template>
                  </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <template v-else>
            <v-alert
              border="right"
              colored-border
              type="info"
            >
              <span
                class="blue--text text-center"
              >
                CHƯA CÓ BÀI VIẾT
              </span>
            </v-alert>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container v-if="items.length === 0">
      <span class="text-h4">Đang tải dữ liệu...</span>
    </v-container>
    <v-container v-if="items === false">
      <span class="text-h4 red--text">Không tìm thấy bài viết !!!</span>
    </v-container>
    <loading :status="loading" />
  </div>
</template>

<script>
import validations from '@/helpers/validations.js'
import TopicService from '@/modules/baiviet.js'
import Header from '@/components/base/pagehead.vue'
import loading from '@/components/base/loading.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {mapGetters} from 'vuex'
export default {
  components: {
    Header,
    loading,
    quillEditor,
  },
  data () {
    return {
      editor: null,
      editReply:undefined,
      Content:undefined,
      loading:false,
      items:[],
      title:undefined,
      Box:false,
      Valid:false,
      loader:false,
    }
  },
  computed:{
    ...mapGetters(['getUser']),
  },
  methods:{
    ...validations,
    showContent(content){
      return content.replace('\n','<br />');
    },
    doCancelUpdate(){
      this.Content = undefined;
      this.editReply = undefined;
    },
    doEditReply(item){
      this.Content = item.Content;
      this.editReply = item._id;
    },
    doReply(){
      this.loading = true;
      const request = {
        id:this.$route.params.id,
        Content: this.Content
      }
      TopicService.NewReply(request).then(resp=>{
        const {success} = resp;
        if(success){
          this.doLoad();
          this.Content = undefined;
        }
      })
    },
    doUpdateReply(){
      this.loading = true;
      const request = {
        id:this.editReply,
        Content: this.Content
      }
      this.Content = undefined; this.editReply = undefined;
      TopicService.UpdateReply(request).then(resp=>{
        const {success} = resp;
        if(success){
          this.doLoad();
        }
      })
    },
    doLoad(){
      this.loading = true;
      const id = this.$route.params.id;
      TopicService.GetReply({id}).then(resp=>{
        const {data} = resp;
        if(data){
          this.items = data.items;
          this.title = data.title;
        }
        else this.items = false;
      }).finally(()=>{
        this.loading = false;
      })
    }
  },
  mounted(){
    this.doLoad();
  }
}
</script>
